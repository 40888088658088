import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RestriccionesComponent } from './restricciones/restricciones.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CabezeraComponent } from './cabezera/cabezera.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { InformacionComponent } from './informacion/informacion.component';
import { MeteoComponent } from './meteo/meteo.component';
import { MarejadaComponent } from './marejada/marejada.component';
import { MaltiempoComponent } from './maltiempo/maltiempo.component';
import { NavesrecalandoComponent } from './navesrecalando/navesrecalando.component';
import { NavesptoComponent } from './navespto/navespto.component';
import { NaveszarpeComponent } from './naveszarpe/naveszarpe.component';
import { AvisosespecialesComponent } from './avisosespeciales/avisosespeciales.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { SearchByNamePipe } from './search-by-name.pipe';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { EstadogralComponent } from './estadogral/estadogral.component';
import { GeneraltablaComponent } from './generaltabla/generaltabla.component';

@NgModule({
  declarations: [
    AppComponent,
    RestriccionesComponent,
    CabezeraComponent,
    InformacionComponent,
    MeteoComponent,
    MarejadaComponent,
    MaltiempoComponent,
    NavesrecalandoComponent,
    NavesptoComponent,
    NaveszarpeComponent,
    AvisosespecialesComponent,
    ConfirmationDialogComponent,
    SearchByNamePipe,
    EstadogralComponent,
    GeneraltablaComponent,
  ],
  imports: [
    BrowserModule, 
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot()
  ],
  entryComponents:[ ConfirmationDialogComponent],
  providers: [ConfirmationDialogService],
  bootstrap: [AppComponent]
})
export class AppModule { }

