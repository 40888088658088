import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObteneripService {
  body: any = {};
  listaIP: any;
  constructor(private http: HttpClient) { }

  obtenerLugarIp() {
    return this.http.get('https://ip.seeip.org/geoip', {}).pipe(
      map(res => {
        this.listaIP = res;
        
        return this.listaIP;
      }));
  }
  
}
