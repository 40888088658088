import { Component, OnInit, OnDestroy  } from '@angular/core';
import { AvisosespecialesService } from './avisosespeciales.service';

@Component({
  selector: 'app-avisosespeciales',
  templateUrl: './avisosespeciales.component.html',
  styleUrls: ['./avisosespeciales.component.css']
})
export class AvisosespecialesComponent implements OnInit, OnDestroy  {
  [x: string]: any;

  listaAvisos:any;
  ayudaaviso="Especial";
  auxmarejada=0;
  auxmaltiempo=0;
  auxespecial=0;
  contador=300;
  timer:any;
  constructor(private avisos:AvisosespecialesService) { 
    this.contador=300;
    this.Reinicio();
  }

  ngOnInit() {
    //this.buscarAvisos();
  }

  ngOnDestroy(){
    clearInterval(this.timer);
  }

  Reinicio()
  {
    clearInterval(this.timer)
      this.contador=300;
      this.buscarAvisos();
      this.fncontador();
  }
  
  fncontador() {
    this.timer = setInterval(() => {
      if (this.contador <= 0) {
  
        this.Reinicio();
      } else {
        this.contador -= 1;
      }
    }, 1000);
  }


  buscarAvisos(){
    this.auxmarejada=0;
    this.auxmaltiempo=0;
    this.auxespecial=0;
    
    this.listaAvisos=[];
    return this.avisos.porAvisos().subscribe(res=>{
    this.listaAvisos=res;
   
    this.listaAvisos.forEach(element => {
  
      
      if(element.tipo==='Mal Tiempo')
      {
        this.auxmaltiempo+=1;
      }
      if(element.tipo==='Especial')
      {
        this.auxespecial+=1;
      }
      if(element.tipo==='Marejadas')
      {
        this.auxmarejada +=1;
      }
  
  
    
  });
   
    
    })
  }

}
