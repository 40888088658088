import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class MarejadaService {
 //baseUrl='http://jmartinezc-dev.dgtm.cl:3008';
 baseUrl='https://orion.directemar.cl/sitport/back'
body: any = {};
  
  listaavisos:any;

  constructor(private http: HttpClient) { }
  

  porAvisos() {
    return this.http.get(this.baseUrl + '/users/avisos', {}).pipe(
      map(res => {
        this.listaavisos = res;
        
        return this.listaavisos;
      }));
  }



}
