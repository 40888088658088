import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchByName'
})
export class SearchByNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {

   if (!value) { return null; }
   if (!args) { return value; }
    args=args.toUpperCase();
   if (isNaN(args)) {
     return value.filter(function (item) {
       return JSON.stringify(item.NMBahia).toUpperCase().includes(args);
     });
   } 
  }

}
