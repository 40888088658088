import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestriccionService } from '../restricciones/restriccion.service';
import{MeteoService} from './meteo.service';

@Component({
  selector: 'app-meteo',
  templateUrl: './meteo.component.html',
  styleUrls: ['./meteo.component.css']
})
export class MeteoComponent implements OnInit, OnDestroy {
  [x: string]: any;
  listaBahia: any[];
  listaPronosticoBahia:any[];
  listaPronosticouno:any[];
  bahiainicio:any;
  loading=false;
  auxocultar:any;
  auxcomparar="1";
  auxexitemeteo="0";
  auxiniciometeo="0";
  contador=300;
  timer:any;
  query:any;
  auxmarejada = 0;
  listaAvisos: any;
  constructor(private meteo:MeteoService, private restriccion:RestriccionService) { }

  ngOnInit() {
    this.buscarPronosticoBahias();
    this.buscarBahia();
    this.auxiniciometeo="0";
    this.Reinicio();
    this.buscarAvisos();
  }

  ngOnDestroy(){
    clearInterval(this.timer);
  }

  Reinicio()
  {
    clearInterval(this.timer)
      this.contador=300;
      this.buscarPronosticoBahias();

      this.buscarBahia();
      if(this.auxiniciometeo=="1")
      {
        this.CambioMeteo(this.bahiainicio);

      }
      
      this.fncontador();
  }
  
  fncontador() {
    this.timer = setInterval(() => {
      if (this.contador <= 0) {
  
        this.Reinicio();
      } else {
        this.contador -= 1;
      }
    }, 1000);
  }



  CambioMeteo(idbahia){
    this.contador=300;
    this.auxiniciometeo="1";
    this.auxexitemeteo="0";
    this.auxocultar="0";
    this.loading=true;
    this.listaPronosticouno=[];
    this.bahiainicio=idbahia;
    return this.meteo.porPronosticoBahiaUnico(idbahia).subscribe(res=>{
        this.listaPronosticouno=res;
              if(res!==null)
                  {
                   this.auxocultar="1";
                    }
                   

                    this.listaPronosticoBahia.forEach(element => {

                      if(element.bahia!==null)
                      {
                      if(element.bahia.idBahia===idbahia)
                      {
                        this.auxexitemeteo="1";
                      }
}
                  
                  
                    
                  });


                  

    this.loading=false
    })
  }
  

buscarBahia(){
  this.listaBahia=[];
  return this.meteo.porBahia().subscribe(res=>{
   this.listaBahia=res.recordset;
 



  })
}

buscarAvisos() {
  this.auxmarejada = 0;
 
  
  this.listaAvisos = [];
  return this.restriccion.porAvisos().subscribe(res => {
    this.listaAvisos = res;

    this.listaAvisos.forEach(element => {

      if (element.tipo === 'Marejadas') {
        this.auxmarejada += 1;
      }



    });


  })
}


buscarPronosticoBahias(){
  this.listaPronosticoBahia=[];
  return this.meteo.porPronosticoBahiaTodas().subscribe(res=>{
  this.listaPronosticoBahia=res;
  })
}

buscarPronosticouno(aux){

  
  this.listaPronosticouno=[];
  return this.meteo.porPronosticoBahia(aux).subscribe(res=>{
  this.listaPronosticouno=res;
  
  
  })
}

}
