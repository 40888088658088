import { Component, OnInit } from '@angular/core';
import { NavesrecalandoService } from './navesrecalando.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RestriccionService } from '../restricciones/restriccion.service';

@Component({
  selector: 'app-navesrecalando',
  templateUrl: './navesrecalando.component.html',
  styleUrls: ['./navesrecalando.component.css']
})
export class NavesrecalandoComponent implements OnInit {
  [x: string]: any;
  listaCapuerto: any[];
  listaBahia: any[];
  bahiainicio:any;
  auxidcapuerto:any;
  nombreCapuerto:any;
  loading=false;
  auxbusqueda=0;
  auxtotal=0;
  auxid='';
  auxglnombre='';
  auxhora:any;
  auxnombrels:any;
  auxidls:any;
  auxingreso=0;
  query:any;
  auxiliarColorRecalando=0;
  auxmarejada = 0;
  listaAvisos: any;
  constructor(private recalando:NavesrecalandoService,private dialog:ConfirmationDialogService,private Sanitizer: DomSanitizer, private restriccion:RestriccionService) { }

  ngOnInit() {
    this.auxbusqueda=0
    this.ingreso=0;
    this.buscarBahia();
    this.todasRecalando();
    this.buscarAvisos();
    
  }




  buscarBahia(){
    
    this.listaBahia=[];
    return this.recalando.porBahia().subscribe(res=>{
     this.listaBahia=res.recordset;
     
     this.auxid="0";
     this.auxglnombre="0";
     this.auxidcapuerto="0";
     this.nombreCapuerto="0";
     this.CambioNaves(this.auxid,this.auxglnombre);

  
    })
    


  }

  buscarAvisos() {
    this.auxmarejada = 0;
   
    
    this.listaAvisos = [];
    return this.restriccion.porAvisos().subscribe(res => {
      this.listaAvisos = res;

      this.listaAvisos.forEach(element => {

        if (element.tipo === 'Marejadas') {
          this.auxmarejada += 1;
        }



      });


    })
  }

  GuardarDatosPuerto(idrepar,nombrecap){
    localStorage.clear();
    localStorage.setItem("idrepar", JSON.stringify(idrepar));
    localStorage.setItem("nombrecap", JSON.stringify(nombrecap));
    
  
  
  }
    
  GuardarDatosZarparon(idrepar,nombrecap){
    localStorage.clear();
    localStorage.setItem("idrepar", JSON.stringify(idrepar));
    localStorage.setItem("nombrecap", JSON.stringify(nombrecap));
  
  
  }

  Ficha(idmovil,caracteristica){
   
    return this.recalando.FichaNave(idmovil).subscribe(res=>{
      
      this.fichanave = {'res':res, 'prueba':this.Sanitizer.bypassSecurityTrustResourceUrl('data:'+res[1].imagennave.contentType+';base64,'+res[1].imagennave.base64), 'caracteristica':caracteristica,'link':"http://zeta2.dgtm.cl/GrafimarWebSian/index.asp?p="+caracteristica};

      this.dialog.confirm('Ficha de la Nave',this.fichanave)
   
 

     })


    
    

    
  }


  ActualizarTotalRecalando(){
    this.todasRecalando();

  }


  todasRecalando(){
    this.loading=true;
    this.listaRecalando=[];
    this.auxtotalrecalando=0;
    this.auxiliarColorRecalando=0;
    return this.recalando.porNaveRecalando().subscribe(res=>{
      this.listaRecalando=res.recordset;
     this.auxtotalrecalando=this.listaRecalando.length;
     
     this.auxhora=new Date();
     this.loading=false;
  
    })
    


  }
  CambioValorBusqueda(){
    this.auxidcapuerto=0;
    this.auxbusqueda=0;
    this.ingreso=0;

  }


  CambioNaves(idrepar,nombrecap){
    this.auxnombrels=JSON.parse(localStorage.getItem("nombrecap"));
    this.auxidls=JSON.parse(localStorage.getItem("idrepar"));

    this.loading=true;
    this.auxid=idrepar;
    this.auxglnombre=nombrecap;
    this.listaRestriccion=[];
    this.auxtotal=0;
    this.auxiliarColorRecalando=1;
if(idrepar==="0" && this.auxnombrels===null)
{
  
  this.ingreso=0;
  
}
else{
 
  this.ingreso=1;

    if(this.auxnombrels!==null)
      {
        this.auxidcapuerto=this.auxidls;
        this.nombreCapuerto=this.auxnombrels;
        this.auxid=this.auxidls;
        this.auxglnombre=this.auxnombrels;
        localStorage.clear();

    }else{
        this.auxidcapuerto=idrepar;
        this.nombreCapuerto=nombrecap;
        localStorage.clear();
    }


    
    
    return this.recalando.porNaveRecalando().subscribe(res=>{
      this.listaRestriccion=res.recordset;
      
      this.auxbusqueda=1;
      this.listaRestriccion.forEach(element => {
        if(element.idBahia===this.auxidcapuerto)
        {
          this.auxtotal+=1;

        }
        
      });
      this.loading=false;
      this.auxhora=new Date();

      
     })
  
  }
  
}

}
