import { Component, OnInit } from '@angular/core';
import { NavesptoService } from './navespto.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RestriccionService } from '../restricciones/restriccion.service';

@Component({
  selector: 'app-navespto',
  templateUrl: './navespto.component.html',
  styleUrls: ['./navespto.component.css']
})
export class NavesptoComponent implements OnInit {
  [x: string]: any;
  listaCapuerto: any[];
  listaBahia: any[];
  bahiainicio:any;
  auxidcapuerto:any;
  nombreCapuerto:any;
  loading=false;
  auxbusqueda=0;
  auxtotal=0;
  auxid='';
  auxglnombre='';
  auxhora:any;
  auxnombrels:any;
  auxidls:any;
  auxingreso=0;
  auxiliarColorPto=0;
  auxmarejada = 0;
  listaAvisos: any;
  constructor(private enpto:NavesptoService, private dialog:ConfirmationDialogService, private Sanitizer: DomSanitizer, private restriccion:RestriccionService ) { }

  ngOnInit() {
  


    this.ingreso=0;
    this.buscarBahia();
    this.todasPto();
    this.buscarAvisos();
  }


GuardarDatosRecalando(idrepar,nombrecap){
  localStorage.clear();
  localStorage.setItem("idrepar", JSON.stringify(idrepar));
  localStorage.setItem("nombrecap", JSON.stringify(nombrecap));
  


}
  
GuardarDatosZarparon(idrepar,nombrecap){
  localStorage.clear();
  localStorage.setItem("idrepar", JSON.stringify(idrepar));
  localStorage.setItem("nombrecap", JSON.stringify(nombrecap));


}

  CambioNaves(idrepar,nombrecap){
    this.auxnombrels=JSON.parse(localStorage.getItem("nombrecap"));
    this.auxidls=JSON.parse(localStorage.getItem("idrepar"));
    this.loading=true;
    this.auxtotal=0;
    this.auxid=idrepar;
    this.auxglnombre=nombrecap;
    this.listaRestriccion=[];
    this.auxiliarColorRecalando=1;
    if(idrepar==="0" && this.auxnombrels===null)
{
  
  this.ingreso=0;
  
}
else
{this.ingreso=1;
    if(this.auxnombrels!==null)
    {
      this.auxidcapuerto=this.auxidls;
      this.nombreCapuerto=this.auxnombrels;
      this.auxid=this.auxidls;
      this.auxglnombre=this.auxnombrels;
      localStorage.clear();

  }else{
      this.auxidcapuerto=idrepar;
      this.nombreCapuerto=nombrecap;
      localStorage.clear();
  }



    return this.enpto.porNaveenPto().subscribe(res=>{
      this.listaRestriccion=res.recordset;
      
      this.auxbusqueda=1;

      this.listaRestriccion.forEach(element => {
        if(element.idBahia===this.auxidcapuerto)
        {
          this.auxtotal+=1;

        }
        
      });


      this.auxhora=new Date();
      this.loading=false;
     })
    }
}

  buscarBahia(){
    
    this.listaBahia=[];
    return this.enpto.porBahia().subscribe(res=>{
     this.listaBahia=res.recordset;
      
      this.auxid="0";
    this.auxglnombre="0";
    this.auxidcapuerto="0";
    this.nombreCapuerto="0";
    this.CambioNaves(this.auxid,this.auxglnombre);

    })
  }

  ActualizarTotalPto(){
    this.todasPto();
    
    
  }

  buscarAvisos() {
    this.auxmarejada = 0;
   
    
    this.listaAvisos = [];
    return this.restriccion.porAvisos().subscribe(res => {
      this.listaAvisos = res;

      this.listaAvisos.forEach(element => {

        if (element.tipo === 'Marejadas') {
          this.auxmarejada += 1;
        }



      });


    })
  }


  todasPto(){
    this.loading=true;
    this.listaenPtos=[];
    this.auxtotalptos=0;
    this.auxiliarColorRecalando=0;
    return this.enpto.porNaveenPto().subscribe(res=>{
      this.listaenPtos=res.recordset;
     this.auxtotalptos=this.listaenPtos.length;
  
     
     this.auxhora=new Date();
     this.loading=false;
  
    })
    


  }
  CambioValorBusqueda(){
    this.auxidcapuerto=0;
    this.auxbusqueda=0;
    this.ingreso=0;

  }


  Ficha(idmovil,caracteristica){
   
    return this.enpto.FichaNave(idmovil).subscribe(res=>{
      
      this.fichanave = {'res':res, 'prueba':this.Sanitizer.bypassSecurityTrustResourceUrl('data:'+res[1].imagennave.contentType+';base64,'+res[1].imagennave.base64), 'caracteristica':caracteristica,'link':"http://zeta2.dgtm.cl/GrafimarWebSian/index.asp?p="+caracteristica};

      this.dialog.confirm('Ficha de la Nave',this.fichanave)
   
 

     })


    
    

    
  }


}
