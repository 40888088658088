import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class InformacionService {
 
  //baseUrl='http://jmartinezc-dev.dgtm.cl:3008';
 baseUrl='https://orion.directemar.cl/sitport/back'
  body: any = {};
    listaGob: any;
    listaCap: any;
    listaBa: any;
    listaZona: any;
    listaRest: any;
    listaenPuerto:any;
    listaRecalando:any;
    listazarpe:any;
    listaenCptoPuerto: any;
    listaCptoRecalando: any;
    listaCptozarpe: any;
    listapronosticobahia:any;
    listapronostico:any;
    listacptorestriccion:any;
    listaCapzo: any;
    listaTotal: any;
    listaGral: any;
   constructor(private http: HttpClient) { }
   

  
  porBahia() {
    return this.http.post(this.baseUrl + '/users/consultaBahias', {}).pipe(
      map(res => {
        this.listaBa = res;
        return this.listaBa;
      }));
  }
  porZonas() {
    return this.http.post(this.baseUrl + '/users/consultaZonas', {}).pipe(
      map(res => {
        this.listaZona = res;
        return this.listaZona;
      }));
  }

  porZonasCap() {
    return this.http.post(this.baseUrl + '/users/consultareparzona', {}).pipe(
      map(res => {
        this.listaCapzo = res;
        return this.listaCapzo;
      }));
  }


  


  porCapuertoRestriccion() {
    return this.http.post(this.baseUrl + '/users/consultaCapuertoRestriccion', {}).pipe(
      map(res => {
        this.listacptorestriccion = res;
        return this.listacptorestriccion;
      }));
  }
  
  porRestriccion() {
    return this.http.post(this.baseUrl + '/users/consultaRestricciones', {}).pipe(
      map(res => {
        this.listaRest = res;
        return this.listaRest;
      }));
  }
  
  
  porNaveZarpando() {
    return this.http.post(this.baseUrl + '/users/consultaNavezarpe', {}).pipe(
      map(res => {
        this.listazarpe = res;
        return this.listazarpe;
      }));
  }
  
  porPronosticoBahiaTodas() {
    return this.http.get('https://sitport.directemar.cl/api/meteo/pronosticos/', {}).pipe(
      map(res => {
        this.listapronosticobahia = res;
       
        return this.listapronosticobahia;
      }));
  }


  porPronosticoTodos() {
    return this.http.get(this.baseUrl + '/users/Totalpronostico', {}).pipe(
      map(res => {
        this.listaTotal= res;
       
        return this.listaTotal;
      }));
  }
  
  porResumenGeneral() {
    return this.http.get(this.baseUrl + '/users/Totalgeneral', {}).pipe(
      map(res => {
        this.listaGral= res;
       
        return this.listaGral;
      }));
  }
  
  
  porPronosticoBahia(aux) {
    return this.http.get('https://sitport.directemar.cl/api/meteo/meteoActual/'+aux, {}).pipe(
      map(res => {
  
  
        this.listapronostico = res;
        
        return this.listapronostico;
      }));
  }
  
  porPronosticoBahiaUnico(aux) {
    return this.http.get(this.baseUrl + '/users/external-api/'+aux, {}).pipe(
      map(res => {
  
  
        this.listapronostico = res;
        
        return this.listapronostico;
      }));
  }
  
}
