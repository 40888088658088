import { Component,Input, OnInit,NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ObteneripService } from '../services/obtenerip.service';

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  resultadoIp:any;
  aux: number;
  mostrar:number;


  constructor(private activeModal: NgbActiveModal,  private buscaip:ObteneripService) { }

  ngOnInit() {
    this.mostrar=1;
    this.buscarLugarIp();
  }
  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {

    this.activeModal.dismiss();
  }
 
  buscarLugarIp() {
    this.resultadoIp = [];
    return this.buscaip.obtenerLugarIp().subscribe(res => {
      this.resultadoIp = res;

      this.aux=this.resultadoIp.organization.indexOf('AS27946');
      
        if(this.aux==0)
        {
          this.mostrar=0;
        }
        else
        {
          this.mostrar=1;
        }
    })
  }

}
