import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavesrecalandoService {
//baseUrl='http://jmartinezc-dev.dgtm.cl:3008';
baseUrl='https://orion.directemar.cl/sitport/back'
  body: any = {};
    
    listacap:any;
    listaBa: any;
    listaRecalando:any;
    fichanave: Object;

  constructor(private http: HttpClient) { }

  porNaveRecalando() {
    return this.http.post(this.baseUrl + '/users/consultaNaveRecalando', {}).pipe(
      map(res => {
        this.listaRecalando = res;
        return this.listaRecalando;
      }));
  }
  
  porBahia() {
    return this.http.post(this.baseUrl + '/users/consultaBahias', {}).pipe(
      map(res => {
        this.listaBa = res;
        return this.listaBa;
      }));
  }
  FichaNave(idmovil){
    return this.http.get(this.baseUrl + '/users/FichaNave/'+idmovil).pipe(
      map(res => {
        this.fichanave = res;
        return this.fichanave;
      }));
  }

}
