import { Component, OnInit, OnDestroy } from '@angular/core';
import { MarejadaService } from './marejada.service';

@Component({
  selector: 'app-marejada',
  templateUrl: './marejada.component.html',
  styleUrls: ['./marejada.component.css']
})
export class MarejadaComponent implements OnInit, OnDestroy {
  [x: string]: any;

  listaAvisos:any;
  ayudamarejada="Marejadas";
  auxmarejada=0;
  auxmaltiempo=0;
  auxespecial=0;
  contador=300;
  timer:any;

  constructor(private marejada:MarejadaService) { 
    this.contador=300;
    this.Reinicio();
  }

  ngOnInit() {
//  this.buscarAvisos();
 
  }


  ngOnDestroy(){
    clearInterval(this.timer);
  }

  Reinicio()
  {
    clearInterval(this.timer)
      this.contador=300;
      this.buscarAvisos();
      this.fncontador();
  }
  
  fncontador() {
    this.timer = setInterval(() => {
      if (this.contador <= 0) {
  
        this.Reinicio();
      } else {
        this.contador -= 1;
      }
    }, 1000);
  }


  buscarAvisos(){
    this.auxmarejada=0;
    this.auxmaltiempo=0;
    this.auxespecial=0;
    
    this.listaAvisos=[];
    return this.marejada.porAvisos().subscribe(res=>{
    this.listaAvisos=res;
   
    this.listaAvisos.forEach(element => {
  
      
      if(element.tipo==='Mal Tiempo')
      {
        this.auxmaltiempo+=1;
      }
      if(element.tipo==='Especial')
      {
        this.auxespecial+=1;
      }
      if(element.tipo==='Marejadas')
      {
        this.auxmarejada +=1;
      }
  
  
    
  });
   
    
    })
  }





}
