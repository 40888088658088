import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeteoService {


//baseUrl='http://jmartinezc-dev.dgtm.cl:3008';
baseUrl='https://orion.directemar.cl/sitport/back'
body: any = {};
  listaGob: any;
  listaCap: any;
  listaBa: any;
  listaRest: any;
  listaenPuerto:any;
  listaRecalando:any;
  listazarpe:any;
  listaenCptoPuerto: any;
  listaCptoRecalando: any;
  listaCptozarpe: any;
  listapronosticobahia:any;
  listapronostico:any;
 constructor(private http: HttpClient) { }
 



porBahia() {
  return this.http.post(this.baseUrl + '/users/consultaBahias', {}).pipe(
    map(res => {
      this.listaBa = res;
      return this.listaBa;
    }));
}



porPronosticoBahiaTodas() {
  return this.http.get('https://sitport.directemar.cl/api/meteo/pronosticos/', {}).pipe(
    map(res => {
      this.listapronosticobahia = res;
      
      return this.listapronosticobahia;
    }));
}



porPronosticoBahia(aux) {
  return this.http.get('https://sitport.directemar.cl/api/meteo/meteoActual/'+aux, {}).pipe(
    map(res => {


      this.listapronostico = res;
      
      return this.listapronostico;
    }));
}

porPronosticoBahiaUnico(aux) {
  return this.http.get(this.baseUrl + '/users/external-api/'+aux, {}).pipe(
    map(res => {


      this.listapronostico = res;
      
      return this.listapronostico;
    }));
}


}
