import { Component, OnInit, OnDestroy} from '@angular/core';
import { RestriccionService } from './restriccion.service';


@Component({
  selector: 'app-restricciones',
  templateUrl: './restricciones.component.html',
  styleUrls: ['./restricciones.component.css']
})
export class RestriccionesComponent implements OnInit, OnDestroy  {
  [x: string]: any;
  listaGobernacion: any[];
  listaCapuerto: any[];
  listaBahia: any[];
  listaRestriccion: any[];
  listaPronostico: any[];
  listaNavePuertos: any[];
  listaNaveRecalando: any[];
  listaNaveCptoPuertos: any[];
  listaNaveCptoZarparon: any[];
  listaNaveCptoRecalando: any[];
  listaPronosticoBahia: any[];
  listaPronosticouno: any[];
  bahiainicio: any;
  loading = false;
  listaAvisos: any;
  auxaviso = 0;
  auxocultar: any;
  auxcomparar = "1";
  auxmarejada = 0;
  auxmaltiempo = 0;
  auxespecial = 0;

  auxingreso = 0;
  auxnombrebahia: any;
  auxtotalrecalando = 0;
  auxtotalrestriccion = 0;
  auxtotalpto = 0;
  auxtotalzarpe = 0;
  mostrarsininfo = 0;
  auxexitemeteo = 0;
  contador=300;
  timer:any;
  query:any;
  constructor(private restriccion: RestriccionService) {
    this.contador=300;
  
    this.Reinicio();
   }

  ngOnInit() {




  }
  ngOnDestroy(){
    clearInterval(this.timer);
  }

Reinicio()
{
  clearInterval(this.timer)
    this.contador=300;
    this.buscarBahia();
    this.buscarRestriccion();
    this.buscarNavesPuerto();
    this.buscarNavesRecalando();
    this.buscarNavesZarparon();
    this.buscarPronosticoBahias();
    this.auxaviso = 0;
    this.buscarAvisos();
    this.fncontador();
    if(this.bahiainicio!==undefined)
    {
    this.CambioInformacion(this.bahiainicio,this.auxnombrebahia );
    }
   
}

fncontador() {
  this.timer = setInterval(() => {
    if (this.contador <= 0) {

      this.Reinicio();
    } else {
      this.contador -= 1;
    }
  }, 1000);
}



  CambioInformacion(idbahia, nombrebahia) {
    this.contador=300;
    this.auxnombrebahia = nombrebahia;
    this.auxingreso = 1;

    this.auxtotalrestriccion = 0;
    this.auxtotalrecalando = 0;
    this.auxtotalzarpe = 0;
    this.auxtotalpto = 0;
    this.auxocultar = "0";
    this.loading = true;
    this.listaPronosticouno = [];
    this.bahiainicio = idbahia;
    this.mostrarsininfo = 0;
    this.auxexitemeteo = 0;




    return this.restriccion.porPronosticoBahiaUnico(idbahia).subscribe(res => {

      this.listaPronosticouno = res;

      if (res !== null) {

        this.auxocultar = "0";
        //
      }
      if (res === null) {

        this.auxexitemeteo += 1;

        //
      } else {
        if (res.status === 500) {

          this.auxexitemeteo += 1;

        }
        else {
          this.mostrarsininfo += 1;
        }
      }


      this.listaRestriccion.forEach(element => {


        if (element.bahia === idbahia) {

          this.auxtotalrestriccion += 1;
          this.mostrarsininfo += 1;
        }




      });

      this.listaNaveRecalando.forEach(element => {


        if (element.idBahia === idbahia) {

          this.auxtotalrecalando += 1;
          this.mostrarsininfo += 1;
        }




      });

      this.listaNavePuertos.forEach(element => {


        if (element.idBahia === idbahia) {

          this.auxtotalpto += 1;
          this.mostrarsininfo += 1;
        }




      });


      this.listaNaveZarparon.forEach(element => {


        if (element.idBahia === idbahia) {

          this.auxtotalzarpe += 1;
          this.mostrarsininfo += 1;
        }




      });



      this.loading = false
    })


  }

  BuscarRestriccion(idrepar, nombrecap) {
    
    localStorage.clear();
    localStorage.setItem("idrepar", JSON.stringify(idrepar));
    localStorage.setItem("nombrecap", JSON.stringify(nombrecap));

  }

  BuscarNaveRec(idrepar, nombrecap) {
    
    localStorage.clear();
    localStorage.setItem("idrepar", JSON.stringify(idrepar));
    localStorage.setItem("nombrecap", JSON.stringify(nombrecap));

  }

  BuscarNavePto(idrepar, nombrecap) {
    
    localStorage.clear();
    localStorage.setItem("idrepar", JSON.stringify(idrepar));
    localStorage.setItem("nombrecap", JSON.stringify(nombrecap));

  }

  BuscarNaveZarpe(idrepar, nombrecap) {
    
    localStorage.clear();
    localStorage.setItem("idrepar", JSON.stringify(idrepar));
    localStorage.setItem("nombrecap", JSON.stringify(nombrecap));

  }



  buscarBahia() {
    
    this.listaBahia = [];
    return this.restriccion.porBahia().subscribe(res => {
      this.listaBahia = res.recordset;



    })
  }
  buscarRestriccion() {
    this.listaRestriccion = [];
    return this.restriccion.porRestriccion().subscribe(res => {
      this.listaRestriccion = res.recordset;


    })
  }




  buscarNavesPuerto() {
    
    this.listaNavePuertos = [];
    return this.restriccion.porNaveenPto().subscribe(res => {
      this.listaNavePuertos = res.recordset;
    })
  }

  buscarNavesRecalando() {
    
    this.listaNaveRecalando = [];
    return this.restriccion.porNaveRecalando().subscribe(res => {
      this.listaNaveRecalando = res.recordset;
    })
  }

  buscarNavesZarparon() {
    this.listaNaveZarparon = [];
    return this.restriccion.porNaveZarpando().subscribe(res => {
      this.listaNaveZarparon = res.recordset;

    })
  }


  buscarPronosticoBahias() {
    this.listaPronosticoBahia = [];
    return this.restriccion.porPronosticoBahiaTodas().subscribe(res => {
     
      this.listaPronosticoBahia = res;

    })
  }

  buscarPronosticouno(aux) {

    this.listaPronosticouno = [];
    return this.restriccion.porPronosticoBahia(aux).subscribe(res => {
      this.listaPronosticouno = res;

    })
  }


  buscarAvisos() {
    this.auxmarejada = 0;
    this.auxmaltiempo = 0;
    this.auxespecial = 0;

    this.listaAvisos = [];
    return this.restriccion.porAvisos().subscribe(res => {
      this.listaAvisos = res;

      this.listaAvisos.forEach(element => {


        if (element.tipo === 'Mal Tiempo') {
          this.auxmaltiempo += 1;
        }
        if (element.tipo === 'Especial') {
          this.auxespecial += 1;
        }
        if (element.tipo === 'Marejadas') {
          this.auxmarejada += 1;
        }



      });


    })
  }



}
