import { Component, OnInit } from '@angular/core';
import { InformacionService } from '../informacion/informacion.service';

@Component({
  selector: 'app-generaltabla',
  templateUrl: './generaltabla.component.html',
  styleUrls: ['./generaltabla.component.css']
})
export class GeneraltablaComponent implements OnInit {
  [x: string]: any;
  listaZona: any[];
  listCapRest: any[];
  listaBahia: any[];
  listaCapzo: any[];
  listaRestriccion: any[];
  listaTotal: any[];
  listaGral: any[];
  loading = false;
  habilitar= false;
  constructor( private informacion:InformacionService) { }

  ngOnInit() {
    this.buscarRestriccion();
  }
  buscarRestriccion(){
    this.loading = true;
    this.listaRestriccion=[];
    this.listaBahia=[];
    this.listaZona =[];
    this.listaCapzo =[];
    this.listCapRest=[];
    this.listaTotal=[];
    this.informacion.porRestriccion().subscribe(res1=>{
      this.informacion.porBahia().subscribe(res2=>{

        this.informacion.porZonas().subscribe(res3=>{
          this.informacion.porZonasCap().subscribe(res4=>{
            this.informacion.porCapuertoRestriccion().subscribe(res5=>{
              this.informacion.porPronosticoTodos().subscribe(res6=>{

                this.informacion.porResumenGeneral().subscribe(res7=>{
                  this.listaGral=res7;
                  console.log(this.listaGral);
                  this.loading=false;
             
                 })

                this.listaTotal=res6;
                
               
           
               })

             

              this.listCapRest=res5.recordset;
             
           
             })

            this.listaCapzo=res4.recordset;
            
         
           })

          this.listaZona=res3.recordset;
         
       
         })
        this.listaBahia=res2.recordset;
        
     
      
      })



     this.listaRestriccion=res1.recordset;
     
 
    }
    
    )
    
  }
  cambiaEstado() {
    this.habilitar = !this.habilitar ;
  }

}
