import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NaveszarpeService {
//baseUrl='http://jmartinezc-dev.dgtm.cl:3008';
baseUrl='https://orion.directemar.cl/sitport/back'
  body: any = {};
    
    listacap:any;
    listaenPuerto:any;
    listaBa: any;
    fichanave: Object;
    query:any;
  constructor(private http: HttpClient) { }


  porNavezarpe() {
    
    return this.http.post(this.baseUrl + '/users/consultaNavezarpe', {}).pipe(
      map(res => {
        this.listaenPuerto = res;
        return this.listaenPuerto;
      }));
  }

  porBahia() {
    return this.http.post(this.baseUrl + '/users/consultaBahias', {}).pipe(
      map(res => {
        this.listaBa = res;
        return this.listaBa;
      }));
  }

  FichaNave(idmovil){
    return this.http.get(this.baseUrl + '/users/FichaNave/'+idmovil).pipe(
      map(res => {
        this.fichanave = res;
        return this.fichanave;
      }));
  }

}
