import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RestriccionesComponent } from '../restricciones/restricciones.component';
import { InformacionComponent } from '../informacion/informacion.component';
import { MeteoComponent } from '../meteo/meteo.component';
import { MarejadaComponent } from '../marejada/marejada.component';
import { MaltiempoComponent } from '../maltiempo/maltiempo.component';
import { NavesrecalandoComponent } from '../navesrecalando/navesrecalando.component';
import { NavesptoComponent } from '../navespto/navespto.component';
import { NaveszarpeComponent } from '../naveszarpe/naveszarpe.component';
import { AvisosespecialesComponent } from '../avisosespeciales/avisosespeciales.component';
import { EstadogralComponent } from '../estadogral/estadogral.component';
import { GeneraltablaComponent } from '../generaltabla/generaltabla.component';



const routes : Routes =[
  {path:'restriccion',component:RestriccionesComponent},
  {path:'informacion',component:InformacionComponent},
  {path:'meteo',component:MeteoComponent},
  {path:'marejada',component:MarejadaComponent},
  {path:'maltiempo',component:MaltiempoComponent},
  {path:'recalando',component:NavesrecalandoComponent},
  {path:'navpto',component:NavesptoComponent},
  {path:'navzarpe',component:NaveszarpeComponent},
  {path:'avisos',component:AvisosespecialesComponent},
  {path:'general',component:EstadogralComponent},
  {path:'generaltabla',component:GeneraltablaComponent   },
  { path: '', redirectTo: '/general', pathMatch: 'full' },
  { path: '**', redirectTo: '/general', pathMatch: 'full' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
