import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestriccionService } from '../restricciones/restriccion.service';
import{InformacionService} from './informacion.service';



@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.css']
})
export class InformacionComponent implements OnInit, OnDestroy {
  [x: string]: any;

  listaBahia: any[];
  listaZona: any[];
  listaRestriccion: any[];
  bahiainicio:any;
  nombreBahia:any;
  auxnombrels:any;
  listCapRest: any[];
  auxidls:any;
  auxmostrarrestriccion=0;
  contador=300;
  timer:any;
  query:any;
  auxmarejada = 0;
  listaAvisos: any;
  constructor(private informacion:InformacionService , private restriccion: RestriccionService) { }

  ngOnInit() {
    this.buscarCapRest();
    this.buscarBahia();
    this.buscarRestriccion();
    this.buscarZona();
    this.buscarAvisos();
    this.Reinicio();
  }
  ngOnDestroy(){
    clearInterval(this.timer);
  }

  
  Reinicio()
  {
    clearInterval(this.timer)
      this.contador=300;
      this.buscarBahia();
      this.buscarCapRest();
    
      //this.buscarRestriccion();
      //console.log(this.bahiainicio)
      //console.log(this.nombreBahia)
      this.CambioRestriccion(this.bahiainicio,this.nombreBahia);
      this.fncontador();
  }
  
  fncontador() {
    this.timer = setInterval(() => {
      if (this.contador <= 0) {
  
        this.Reinicio();
      } else {
        this.contador -= 1;
      }
    }, 1000);
  }

buscarBahia(){
  this.listaBahia=[];
  return this.informacion.porBahia().subscribe(res=>{
   this.listaBahia=res.recordset;
   

  })
}

buscarAvisos() {
  this.auxmarejada = 0;
 
  
  this.listaAvisos = [];
  return this.restriccion.porAvisos().subscribe(res => {
    this.listaAvisos = res;

    this.listaAvisos.forEach(element => {

      if (element.tipo === 'Marejadas') {
        this.auxmarejada += 1;
      }



    });


  })
}

buscarZona(){
  this.listaZona =[];
  return this.informacion.porZonas().subscribe(res=>{
   this.listaZona=res.recordset;
   

  })
}
buscarCapRest(){
  this.listCapRest=[];
  return this.informacion.porCapuertoRestriccion().subscribe(res=>{
   this.listCapRest=res.recordset;
   

  })
}


buscarRestriccion(){
  this.auxmostrarrestriccion=0;
  this.auxnombrels=JSON.parse(localStorage.getItem("nombrecap"));
  this.auxidls=JSON.parse(localStorage.getItem("idrepar"));

  this.listaRestriccion=[];
  return this.informacion.porRestriccion().subscribe(res=>{
   this.listaRestriccion=res.recordset;
   
   if(this.auxnombrels!==null)
   {
    this.bahiainicio=this.auxidls;
    this.nombreBahia=this.auxnombrels;
     localStorage.clear();
     this.CambioRestriccion(this.bahiainicio,this.nombreBahia);

 }else{
     this.auxidcapuerto=this.listaRestriccion[0].bahia;
     this.nombreCapuerto=this.listaRestriccion[0].GLBahia;
     localStorage.clear();
     this.auxmostrarrestriccion=1;
 }


  })
}

CambioRestriccion(idbahia,nombre){
  this.contador=300;
  this.auxmostrarrestriccion=0;
  this.bahiainicio=idbahia;
  this.nombreBahia=nombre;
  return this.informacion.porRestriccion().subscribe(res=>{
    this.listaRestriccion=res.recordset;
    
 
   })

}



}
